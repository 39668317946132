import { validateMonthlySalaryBeforeTax } from '../../../../validators/FI/monthlySalaryBeforeTax';
import monthlySalaryAfterTax from '../../../../validators/FI/monthlySalaryAfterTax';
import EMPLOYMENT_TYPES from '../../../../localizations/config/FI/shared/employment-types';
import validatePhoneNumberFi from '../../../../validators/FI/phoneNumber';
import { validateRequiredButtonGroup, validateRequiredCheckbox } from '../../../../validators/requiredButtonGroup';
import onlyNumbers from '../../../../validators';
import email from '../../../../validators/FI/email';
import textResource from '../../../../utils/text-resources/rahalaitos/FI/messages';
import submit from '../../../../mappers/FI/application';
import LOAN_LIMITS from '../../../../localizations/config/FI/shared/loan-limits';
import validateTotalLoan from '../../../../validators/totalLoan';

const tooltip = (text) => {
  const temp = {
    trigger: 'focus',
    text,
    width: 'fill',
    position: 'right',
  };
  return temp;
};

const dataLinks = {
  quickReapply: '/returning-customer/',
  quickReapplyEng: '/en/returning-customer/',
  userAgreementEng: 'https://www.rahalaitos.fi/en/user-agreement/',
  userAgreement: 'https://www.rahalaitos.fi/kayttajaehdot',
  dataPolicy: 'https://www.rahalaitos.fi/tietosuojaseloste',
  dataPolicyEng: 'https://www.rahalaitos.fi/en/data-protection-policy/',
};

const data = {
  general: {
    mapper: submit,
  },
  backgroundColor: 'var(--white-opacity)',
  links: dataLinks,
  slides: [
    {
      title: '',
      coApplicantSlide: false,
      fields: [
        {
          component: 'StoreValueDisplay',
          props: {
            label: textResource.applicationLoanAmountLabel,
            value: 'totalAmount',
            fontWeight: 'bold',
            position: 'space-between',
            fontSize: '1rem',
            fieldDisplay: {
              name: 'totalAmount',
              validate: validateTotalLoan,
              validateOnRegister: true,
              maxValue: LOAN_LIMITS.UP_TO,
              type: 'tel',
              suffix: textResource.loanCalculatorCurrencySuffix,
              placeholder: textResource.loanCalculatorTotalLoanAmountPlaceholder,
              hasValidateFunction: true,
              isNumber: true,
              unstyled: true,
            },
            layout: {
              start: 1,
              end: 4,
              mobileOrder: '-1',
            },
          },
        },
        {
          component: 'StoreValueDisplay',
          props: {
            label: textResource.repaymentTimeLabel,
            value: 'repaymentTime',
            fontWeight: 'bold',
            position: 'space-between',
            fontSize: '1rem',
            fieldDisplay: {
              suffix: textResource.loanCalculatorRepaymentTimeSuffix,
              name: 'repaymentTime',
              type: 'tel',
              maxValue: 20,
              placeholder: textResource.repaymentTimePlaceholder,
              validate: onlyNumbers,
              hasValidateFunction: true,
              isNumber: true,
              unstyled: true,
            },
            layout: {
              start: 4,
              end: 7,
              mobileOrder: '0',
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Slider',
          props: {
            name: 'totalAmount',
            showButtons: true,
            type: 'tel',
            lead: true,
            validate: validateTotalLoan,
            validateOnRegister: true,
            sliderConfig: {
              range: {
                min: LOAN_LIMITS.FROM,
                max: LOAN_LIMITS.UP_TO,
              },
              step: LOAN_LIMITS.STEP,
            },
            layout: {
              start: 1,
              end: 4,
              mobileOrder: '-1',
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Slider',
          props: {
            name: 'repaymentTime',
            showButtons: true,
            type: 'tel',
            lead: true,
            sliderConfig: {
              range: {
                min: 1,
                max: 20,
              },
            },
            layout: {
              start: 4,
              end: 7,
              mobileOrder: '0',
            },
          },
        },
        {
          component: 'MonthlyCost',
          props: {
            label: textResource.monthlyCostApproximateMonthlyCostLabel,
            approximateMonthlyCostMobile: textResource.approximateMonthlyCostMobile,
            repaymentTimeLimit: '>5',
            minimal: true,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Checkbox',
          props: {
            name: 'hasCoApplicant',
            label: textResource.addCoApplicantDesc,
            text: textResource.addCoApplicantDesc,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'Break',
          props: {
            shade: 'down',
            layout: {
              start: 1,
              end: 6,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'phoneNumber',
            label: textResource.phoneNumberLabel,
            type: 'tel',
            validate: validatePhoneNumberFi,
            hasValidateFunction: true,
            tooltip: tooltip(textResource.phoneNumberTooltip),
            placeholder: textResource.phoneNumberPlaceholder,
            lead: true,
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'EmailInput',
          sectionName: 'applicant',
          props: {
            name: 'email',
            label: textResource.emailLabel,
            placeholder: textResource.emailPlaceholder,
            hasValidateFunction: true,
            lead: true,
            tooltip: tooltip(textResource.emailTooltip),
            validate: email,
            layout: {
              start: 3,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'monthlyIncomeBeforeTax',
            isNumber: true,
            label: textResource.monthlyIncomeBeforeTaxLabel,
            placeholder: textResource.monthlyIncomeBeforeTaxPlaceholder,
            validate: validateMonthlySalaryBeforeTax,
            hasValidateFunction: true,
            type: 'tel',
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'monthlyIncomeAfterTax',
            isNumber: true,
            label: textResource.monthlyIncomeAfterTaxLabel,
            placeholder: textResource.monthlyIncomeAfterTaxPlaceholder,
            validate: monthlySalaryAfterTax,
            hasValidateFunction: true,
            type: 'tel',
            layout: {
              start: 3,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'applicant',
          props: {
            name: 'employmentType',
            label: textResource.employmentTypeLabel,
            placeholder: textResource.employmentTypePlaceholder,
            hasValidateFunction: true,
            options: EMPLOYMENT_TYPES,
            validate: validateRequiredButtonGroup,
            tooltip: tooltip(textResource.employmentTypeTooltip),
            layout: {
              start: 5,
              end: 7,
            },
          },
        },
        {
          component: 'CreditCheckConsent',
          form: 'Checkbox',
          props: {
            name: 'creditCheckConsent',
            text: textResource.acceptCreditCheck,
            linkText: textResource.samblaPlusReadMore,
            links: 'https://www.rahalaitos.fi/kyselyjarjestelma',
            hasValidateFunction: true,
            validate: validateRequiredCheckbox,
            linkInText: false,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'AcceptAgreement',
          form: 'Checkbox',
          props: {
            name: 'acceptAgreement',
            text: textResource.acceptAgreement,
            linkText: textResource.termsAndIntegrityPolicyLinksIntegrityPolicy,
            linkTextPolicy: textResource.termsAndIntegrityPolicyLinksTerms,
            linkDataPolicy: {
              FI: dataLinks.dataPolicy,
              EN: dataLinks.dataPolicyEng,
            },
            linkUserAgreement: {
              FI: dataLinks.userAgreement,
              EN: dataLinks.userAgreementEng,
            },
            hasValidateFunction: true,
            validate: validateRequiredCheckbox,
            linkInText: true,
            layout: {
              start: 1,
              end: 5,
            },
          },
        },
        {
          component: 'AcceptNewsLetter',
          form: 'Checkbox',
          props: {
            name: 'acceptNewsletter',
            text: textResource.acceptNewsletterConsentText,
            layout: {
              start: 1,
              end: 5,
            },
          },
        },
        {
          component: 'Teleporter',
          sectionName: 'applicant',
          props: {
            destinationPath: '/hae-lainaa/',
            engDestinationPath: '/en/personal-loan/',
            sendAbandonCartEvent: true,
            label: textResource.continueLabel,
            ctaBtnStyle: true,
            layout: {
              start: 5,
              end: 7,
            },
          },
        },
      ],
    },
  ],
};

export default data;
